html {
  font-size: 10px;
}
.rs-picker-menu.rs-picker-check-menu{
  z-index:1400;
}
.MuiTableCell-root.MuiTableCell-stickyHeader{
  z-index:7;
}
.MuiPaper-root.MuiDialog-paperWidthSm{
  max-width: none;
}
a.MuiButton-containedPrimary:hover { color: white; }
a { cursor: pointer; }
/* Utility */
.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }
.flex-column { flex-direction: column; }
.justify-center { justify-content: center; }
.align-center { align-items: center; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 12px; }
.mt-4 { margin-top: 16px; }
.mt-5 { margin-top: 20px; }
.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 12px; }
.mb-4 { margin-bottom: 16px; }
.mb-5 { margin-bottom: 20px; }
.mr-1 { margin-right: 4px; }
.mr-2 { margin-right: 8px; }
.mr-3 { margin-right: 12px; }
.mr-4 { margin-right: 16px; }
.mr-5 { margin-right: 20px; }
.mr-6 { margin-right: 24px; }
.ml-1 { margin-left: 4px; }
.ml-2 { margin-left: 8px; }
.ml-3 { margin-left: 12px; }
.ml-4 { margin-left: 16px; }
.ml-5 { margin-left: 20px; }
.ml-6 { margin-left: 24px; }
.px-6 { padding-right: 24px; padding-left: 24px;}